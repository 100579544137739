import React, { FC } from 'react';

import Icon from 'common/IconCustom';

import './CarouselNavigation.scss';
import { IPropsCarouselNavigation } from './models';

const CarouselNavigation: FC<IPropsCarouselNavigation> = ({
  ariaLabelNext,
  ariaLabelPrev,
  navPrevRef,
  navNextRef,
}) => (
  <div data-type="CarouselNavigation" className="navigation">
    <button
      type="button"
      className="navigation-item navigation-item-prev"
      aria-label={ariaLabelPrev}
      ref={navPrevRef}
    >
      <Icon icon="arrow" className="icon-prev" />
    </button>
    <button
      type="button"
      className="navigation-item navigation-item-next"
      aria-label={ariaLabelNext}
      ref={navNextRef}
    >
      <Icon icon="arrow" className="icon-next" />
    </button>
  </div>
);

export default CarouselNavigation;
