import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import { Container } from 'layout';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import Title from 'common/Title';
import IconFeatureList from 'components/IconFeatureList';

import './PromoElement.scss';
import './ShadowPromoElement.scss';
import { IPropsPromoElement } from './models';

const PromoElement: FC<IPropsPromoElement> = ({ promoElement }): ReactElement | null => {
  const {
    title,
    bulletPoints,
    ctaButton,
    iconsList,
    mainImage,
    mobileMainImage,
    mainImageAlt,
    mainImageWrapperClass,
    secondaryImage,
    mobileSecondaryImage,
    secondaryImageAlt,
    bgImage,
    mobileBgImage,
    bgImageAlt,
    bottomText,
  } = promoElement[0].properties;

  const ctaButtonData = ctaButton?.[0]?.properties;

  return (
    <div
      data-test="PromoElement"
      className={classNames('promo-element', {
        'promo-element--with-out-main-image': !mainImage,
      })}
    >
      <Container className="promo-element-holder">
        <div className="info-part">
          <Title dataTestAttr="promoElementTitle">{title}</Title>

          <div className="bullet-points-wrapper">
            <ul className="bullet-points">
              {bulletPoints.map(({ properties: { text } }) => (
                <DangerouslySetInnerHtml
                  key={text}
                  element="li"
                  html={text}
                  className="bullet-point"
                />
              ))}
            </ul>
          </div>

          {iconsList ? <IconFeatureList iconsList={iconsList} /> : null}

          {bottomText ? (
            <DangerouslySetInnerHtml html={bottomText} className="promo-bottom-text" />
          ) : null}

          {ctaButtonData ? (
            <div className="cta-btn-wrapper">
              <Button
                link={ctaButtonData.link?.[0].url}
                ariaLabel={ctaButtonData.ariaLabel}
                className="cta-btn"
                textAlign="center"
              >
                {ctaButtonData.label}
              </Button>
            </div>
          ) : null}
        </div>

        <div className="image-background-wrapper">
          <GatsbyImage
            image={{ ...bgImage, mobileImage: mobileBgImage?.gatsbyImage }}
            alt={bgImageAlt}
            className="image-background"
            objectFit="cover"
            objectPosition="0% 0%"
          />
        </div>

        {secondaryImage ? (
          <GatsbyImage
            image={{ ...secondaryImage, mobileImage: mobileSecondaryImage?.gatsbyImage }}
            alt={secondaryImageAlt}
            className="image-secondary"
          />
        ) : null}

        {mainImage ? (
          <GatsbyImage
            image={{ ...mainImage, mobileImage: mobileMainImage?.gatsbyImage }}
            alt={mainImageAlt}
            className={classNames('image-main', {
              [`${mainImageWrapperClass}`]: mainImageWrapperClass,
            })}
          />
        ) : null}
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentPromoElement on TPromoElement {
    properties {
      bulletPoints {
        properties {
          text
        }
      }
      ctaButton {
        properties {
          ariaLabel
          label
          link {
            url
          }
        }
      }
      iconsList {
        ...FragmentIconFeatureList
      }
      mainImage {
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      mobileMainImage: mainImage {
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 250, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      mainImageAlt
      mainImageWrapperClass
      secondaryImage {
        ...FragmentGatsbyImage
      }
      mobileSecondaryImage: secondaryImage {
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 71, quality: 30) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      secondaryImageAlt
      bgImage {
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      mobileBgImage: bgImage {
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 71, quality: 30) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      bgImageAlt
      title
      bottomText
    }
  }
`;

export default PromoElement;
