import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';
import Img from 'gatsby-image/withIEPolyfill';

import useScreenRecognition from 'hooks/useScreenRecognition';

import './MainSlider.scss';
import { IPropsMainSlider } from './models';

const MainSlider: FC<IPropsMainSlider> = ({ items, thumbsSwiper, generalImageAlt }) => {
  const { isDesktop } = useScreenRecognition();

  return (
    <Swiper
      className={classNames(`main-slider`, {
        'single-item': items.length === 1,
      })}
      tag="section"
      wrapperTag="ul"
      spaceBetween={12}
      slidesPerView={1}
      thumbs={{ swiper: thumbsSwiper }}
      pagination={{ clickable: true }}
      centeredSlides
    >
      {items.map((item, index) => {
        if (!item.length) {
          return null;
        }
        const mainImage = item[0];
        const aspectRatio = mainImage?.aspectRatio || 0;

        return (
          <SwiperSlide key={`${mainImage?.src || index}`} tag="li" data-index={index}>
            <div
              className={classNames('current-image-wrapper', {
                horizontal: aspectRatio > 1,
                vertical: aspectRatio < 1,
                'additional-image': index !== 0,
              })}
            >
              <Img
                fluid={isDesktop ? item[1] : item[0]}
                alt={generalImageAlt}
                objectFit="contain"
                loading={index === 0 ? 'eager' : 'lazy'}
                fadeIn={false}
              />
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default MainSlider;
