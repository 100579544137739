import React, { FC, ReactElement } from 'react';

import { UmbracoProductFeature } from '@shared/types';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';

import './ProductFeaturesSecondary.scss';
import './ShadowProductFeaturesSecondary.scss';
import { IPropsProductFeaturesSecondary } from './models';

const ProductFeaturesSecondary: FC<IPropsProductFeaturesSecondary> = ({
  items,
}): ReactElement | null => (
  <div data-test="ProductFeaturesSecondary" className="product-features-secondary">
    {items.map((item: UmbracoProductFeature.IData) => (
      <div className="feature-item" key={item?.iconId?.[0]?.properties?.names?.[0] || item.text}>
        <div className="feature-item-icon-wrapper">
          <GatsbyImage image={item.icon} alt={item.iconAlt} className="feature-item-icon" />
        </div>
        {item?.text ? (
          <div className="feature-item-info">
            <DangerouslySetInnerHtml html={item.text} className="feature-item-text" />
          </div>
        ) : null}
      </div>
    ))}
  </div>
);

export default ProductFeaturesSecondary;
