import { UserEvents, IFloodlightService } from './model';

class FloodlightService implements IFloodlightService {
  userEvents: UserEvents[] = ['scroll', 'mousemove', 'keydown'];

  private _interactionDelay = 5000;

  readonly tag = process.env.GATSBY_GOOGLE_FLOODLIGHT_TAG_ID || '';

  readonly id = this.tag.split('-')[1];

  set interactionDelay(ms: number) {
    this._interactionDelay = ms;
  }

  isScriptAppended(): boolean {
    return Boolean(document.getElementById('floodLightScript'));
  }

  appendFloodlightScript(): void {
    if (this.isScriptAppended()) return;

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${this.tag}`;
    script.type = 'text/javascript';
    script.async = true;
    script.id = 'floodLightScript';

    document.head.appendChild(script);
  }

  fireFloodlightEvent(...args: unknown[]): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ...args });
  }

  loadFloodlightGlobal(): void {
    if (!this.checkEnvironmentVariable(this.tag) || this.isScriptAppended()) {
      return;
    }
    this.interactionEventTrigger();
    this.fireFloodlightEvent('js', new Date());
    this.fireFloodlightEvent('config', this.tag);
  }

  interactionEventHandler = (): void => {
    this.userEvents.forEach((evt) =>
      document.removeEventListener(evt, this.interactionEventHandler)
    );
    this.appendFloodlightScript();
  };

  interactionEventTrigger(): void {
    this.userEvents.forEach((evt) => document.addEventListener(evt, this.interactionEventHandler));

    setTimeout(() => {
      this.interactionEventHandler();
    }, this._interactionDelay);
  }

  getNoScriptUrl(url: string, pageUrl = ''): string {
    return url
      .replace('%ID%', this.id)
      .replace('%URL%', pageUrl)
      .replace(/%RND%/g, Date.now().toString(10));
  }

  checkEnvironmentVariable(tag: string): boolean {
    const isDev = process.env.NODE_ENV !== 'production';

    if (!tag && isDev) {
      console.error('[FLOODLIGHT]: Missing GATSBY_GOOGLE_FLOODLIGHT_TAG_ID variable.');
    }

    return Boolean(tag);
  }
}

export default new FloodlightService();
