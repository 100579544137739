import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';

import Button from 'common/Button';

import './BuyButton.scss';
import { IPropsBuyButton } from './models';

const BuyButton: FC<IPropsBuyButton> = ({
  isStickyBuyButton,
  isMobile,
  buyCTA,
  buttonBuyTitle,
  handleClick,
}): ReactElement | null => {
  const buyCTAFromPage = buyCTA[0].properties.label.trim();

  return (
    <div
      data-test="BuyButton"
      className={classNames('buy-btn', {
        sticky: isStickyBuyButton && isMobile,
      })}
    >
      <Button
        {...(!handleClick && {
          link: buyCTA[0].properties.link?.[0].url,
        })}
        ariaLabel={buyCTA[0].properties.ariaLabel}
        className="cta-btn"
        textAlign="center"
        clickHandler={handleClick}
      >
        {buyCTAFromPage || buttonBuyTitle}
      </Button>
    </div>
  );
};

export default BuyButton;
