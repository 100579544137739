import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { UmbracoProductFeature } from '@shared/types';

import GatsbyImage from 'common/GatsbyImage';

import './ProductFeaturesMain.scss';
import './ShadowProductFeaturesMain.scss';
import { IPropsProductFeaturesMain } from './models';

const ProductFeaturesMain: FC<IPropsProductFeaturesMain> = ({ items }): ReactElement | null => (
  <div data-test="ProductFeaturesMain" className="product-features-main">
    {items.map((item: UmbracoProductFeature.IData) => (
      <div
        key={item?.iconId?.[0]?.properties?.names?.[0] || item.text}
        className={classNames('feature-item', {
          [`feature-item--icon-only`]: !(item?.label && item?.text),
        })}
      >
        <div className="feature-item-icon-wrapper">
          <GatsbyImage image={item.icon} alt={item.iconAlt} className="feature-item-icon" />
        </div>
        {item?.label || item?.text ? (
          <div className="feature-item-info">
            {item?.label ? <strong className="feature-item-title">{item.label}</strong> : null}
            {item?.text ? (
              <p
                className={classNames('feature-item-text', {
                  'feature-item-text--bold': !item?.label,
                })}
              >
                {item.text}
              </p>
            ) : null}
          </div>
        ) : null}
      </div>
    ))}
  </div>
);

export default ProductFeaturesMain;
