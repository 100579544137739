import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import { graphql } from 'gatsby';

import { FrontendProductDetail } from '@shared/types';

import CustomTabs, { ICustomTabsItemData } from 'common/CustomTabs';
import { Container, DangerouslySetInnerHtml } from 'layout';

import './ProductDetails.scss';
import './ShadowProductDetails.scss';
import { IPropsProductDetails } from './models';

const ProductDetails: FC<IPropsProductDetails> = ({ productDetails }): ReactElement | null => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelectCallback = useCallback((index: number) => {
    setSelectedIndex(index);
  }, []);

  const tabsItems: ICustomTabsItemData[] = useMemo(
    () =>
      productDetails[0].properties.items.map((item: FrontendProductDetail.IStructure) => ({
        title: item.properties.title,
        ariaLabel: item.properties?.ariaLabel || item.properties.title,
        content: item.properties?.text ? (
          <DangerouslySetInnerHtml html={item.properties.text} />
        ) : (
          ''
        ),
      })),
    [productDetails]
  );

  return (
    <Container data-test="ProductDetails">
      <div className="product-details">
        <CustomTabs
          items={tabsItems}
          selectedIndex={selectedIndex}
          onSelectCallback={onSelectCallback}
        />
      </div>
    </Container>
  );
};

export const query = graphql`
  fragment FragmentProductDetails on TProductDetailsList {
    properties {
      items {
        properties {
          ariaLabel
          title
          text
        }
      }
    }
  }
`;

export default ProductDetails;
