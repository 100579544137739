import React, { FC, ReactElement } from 'react';

import { UmbracoProductFeature } from '@shared/types';

import GatsbyImage from 'common/GatsbyImage';

import './DietaryFeatures.scss';
import './ShadowDietaryFeatures.scss';
import { IPropsDietaryFeatures } from './models';

const DietaryFeatures: FC<IPropsDietaryFeatures> = ({ items, title }): ReactElement | null => (
  <div data-test="DietaryFeatures" className="dietary-features">
    <h6 className="dietary-features-title">{title}</h6>
    <div className="feature-list">
      {items.map((item: UmbracoProductFeature.IData) => (
        <div className="feature-item" key={item?.iconId?.[0]?.properties?.names?.[0] || item.text}>
          <div className="feature-item-icon-wrapper">
            <GatsbyImage image={item.icon} alt={item.iconAlt} className="feature-item-icon" />
            {item?.text ? (
              <div className="feature-item-tooltip">
                <p className="feature-item-text">{item.text}</p>
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default DietaryFeatures;
