import React, { FC, lazy, ReactElement, Suspense, useCallback, useState } from 'react';
import { useLocation } from '@reach/router';

import Container from 'layout/Container';
import useScreenRecognition from 'hooks/useScreenRecognition';

import ProductCarousel from 'components/ProductCarousel';
import ProductFeaturesMain from 'components/ProductBanner/ProductFeaturesMain';
import ProductFeaturesSecondary from 'components/ProductBanner/ProductFeaturesSecondary';
import DietaryFeatures from 'components/ProductBanner/DietaryFeatures';
import BuyButton from 'components/ProductBanner/BuyButton';
import Title from 'common/Title';

import { eventTypes } from 'common/FloodlightTracker/constants';
import floodLightService from 'utils/floodlightService/floodLightService';

import { IPropsProductBanner } from './models';

import './ProductBanner.scss';
import './ShadowProductBanner.scss';

export const BuyProductPopupLoadable = lazy(() => import('components/BuyProductPopup'));
const renderLoader = () => null;

const ProductBanner: FC<IPropsProductBanner> = ({
  title,
  mainFeaturesItems,
  secondaryFeaturesItems,
  dietaryItems,
  dietaryTitle,
  productCarouselItems,
  buyCTA,
  buyPopup,
  dir,
  buttonBuyTitle,
  ariaLabelPrev,
  ariaLabelNext,
  closeAriaLabel,
  isStickyBuyButton,
  floodlightEvents,
}): ReactElement | null => {
  const [isVisibleBuyProductPopup, setVisibleBuyProductPopup] = useState(false);
  const { href } = useLocation();
  const { isMobile, isDesktop } = useScreenRecognition();

  const handleBuyNowClickEvent = useCallback(() => {
    const buyNowEvent = floodlightEvents?.[eventTypes.BUY_NOW];

    if (buyNowEvent) {
      floodLightService.fireFloodlightEvent('event', 'conversion', {
        allow_custom_scripts: true,
        u1: href,
        send_to: buyNowEvent.sendTo.replace('%TAG%', floodLightService.tag),
      });
    }
  }, []);

  const handlePurchaseClickEvent = useCallback(
    () => () => {
      const purchaseEvent = floodlightEvents?.[eventTypes.PURCHASE];

      if (purchaseEvent) {
        floodLightService.fireFloodlightEvent('event', 'conversion', {
          allow_custom_scripts: true,
          send_to: purchaseEvent.sendTo.replace('%TAG%', floodLightService.tag),
        });
      }
    },
    []
  );

  const handleBuyProductPopupVisibility = useCallback(() => {
    setVisibleBuyProductPopup((oldValue: boolean) => {
      if (!oldValue) handleBuyNowClickEvent();

      return !oldValue;
    });
  }, []);

  return (
    <Container data-test="ProductBanner" fluid className="product-banner">
      <div className="product-banner-images">
        {title && !isDesktop ? (
          <Title dataTestAttr="pageTitle" className="title title-mobile" Tag="h1">
            {title}
          </Title>
        ) : null}
        <ProductCarousel
          dir={dir}
          ariaLabelPrev={ariaLabelPrev}
          ariaLabelNext={ariaLabelNext}
          items={productCarouselItems}
          generalImageAlt={title}
          isFirstViewport
        />
      </div>
      <div className="product-banner-info">
        {title && isDesktop ? (
          <Title dataTestAttr="pageTitle" className="title title-desktop" Tag="h1">
            {title}
          </Title>
        ) : null}
        {mainFeaturesItems?.length ? <ProductFeaturesMain items={mainFeaturesItems} /> : null}
        {secondaryFeaturesItems?.length ? (
          <ProductFeaturesSecondary items={secondaryFeaturesItems} />
        ) : null}
        {dietaryItems?.length ? (
          <DietaryFeatures items={dietaryItems} title={dietaryTitle} />
        ) : null}
        {buyCTA?.length ? (
          <BuyButton
            isStickyBuyButton={isStickyBuyButton}
            buttonBuyTitle={buttonBuyTitle}
            isMobile={isMobile}
            buyCTA={buyCTA}
            handleClick={buyPopup?.length && handleBuyProductPopupVisibility}
          />
        ) : null}
        {buyPopup?.length && isVisibleBuyProductPopup ? (
          <Suspense fallback={renderLoader()}>
            <BuyProductPopupLoadable
              ariaLabelPrev={ariaLabelPrev}
              ariaLabelNext={ariaLabelNext}
              buyPopup={buyPopup}
              handlePopupVisibility={handleBuyProductPopupVisibility}
              closeAriaLabel={closeAriaLabel}
              dir={dir}
              handlePurchaseClickEvent={handlePurchaseClickEvent}
            />
          </Suspense>
        ) : null}
      </div>
    </Container>
  );
};

export default ProductBanner;
