import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';

import Img from 'gatsby-image/withIEPolyfill';

import CarouselNavigation from 'components/ProductCarousel/CarouselNavigation';

import './SecondarySlider.scss';
import { IPropsSecondarySlider } from './models';

const SecondarySlider: FC<IPropsSecondarySlider> = ({
  items,
  slidesInControls,
  isFirstViewport,
  onSwiperHandler,
  onBeforeInitHandler,
  dir,
  isMobile,
  isShowControls,
  ariaLabelPrev,
  ariaLabelNext,
  navPrevRef,
  navNextRef,
  generalImageAlt,
}) => (
  <div data-test="SecondarySlider" className="secondary-slider">
    <Swiper
      className="thumb"
      tag="section"
      wrapperTag="ul"
      spaceBetween={12}
      slidesPerView={slidesInControls}
      onSwiper={onSwiperHandler}
      onBeforeInit={onBeforeInitHandler}
      navigation={{
        prevEl: navPrevRef.current,
        nextEl: navNextRef.current,
      }}
      dir={dir}
      allowSlideNext={isShowControls}
      allowSlidePrev={isShowControls}
      centerInsufficientSlides
      loop={slidesInControls < items.length}
      centeredSlides
      centeredSlidesBounds
      watchSlidesVisibility
      watchSlidesProgress
    >
      {!isMobile
        ? items.map((item, index) => {
            if (!item.length) {
              return null;
            }

            const mainImage = item[0];
            const aspectRatio = mainImage?.aspectRatio || 0;

            return (
              <SwiperSlide tag="li" data-index={index} key={`${mainImage?.src || index}`}>
                <div
                  className={classNames('image-wrapper', {
                    horizontal: aspectRatio > 1,
                    vertical: aspectRatio < 1,
                    'additional-image': index !== 0,
                  })}
                >
                  <Img
                    fluid={item}
                    alt={generalImageAlt}
                    objectFit="contain"
                    loading={isFirstViewport ? 'eager' : 'lazy'}
                  />
                </div>
              </SwiperSlide>
            );
          })
        : null}
    </Swiper>
    {!isMobile && isShowControls ? (
      <CarouselNavigation
        ariaLabelPrev={ariaLabelPrev}
        ariaLabelNext={ariaLabelNext}
        navPrevRef={navPrevRef}
        navNextRef={navNextRef}
      />
    ) : null}
  </div>
);

export default SecondarySlider;
