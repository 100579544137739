import { UmbracoProductFeaturesList, UmbracoProductFeature } from '@shared/types';

const parseProductFeatures = (
  productFeatures: [UmbracoProductFeaturesList.IStructure]
): UmbracoProductFeature.IData[] =>
  productFeatures?.[0]?.properties?.items.map((item) => item.properties) || [];

export default {
  parseProductFeatures,
};
