import { AppImages } from '@shared/types';
import { TABLET_BREAKPOINT } from './constants';

export const getResponsiveImageData = (
  defaultProductImage?: AppImages.IResponsiveImageData,
  mobileProductImage?: AppImages.IResponsiveImageData
): AppImages.IFluidData[] => {
  const responsiveImage: AppImages.IFluidData[] = [];
  if (mobileProductImage?.gatsbyImage?.childImageSharp?.fluid) {
    responsiveImage.push({
      ...mobileProductImage.gatsbyImage.childImageSharp.fluid,
      media: `(min-width: 0px)`,
    });
  }
  if (defaultProductImage?.gatsbyImage?.childImageSharp?.fluid) {
    responsiveImage.push({
      ...defaultProductImage.gatsbyImage.childImageSharp.fluid,
      media: `(min-width: ${TABLET_BREAKPOINT}px)`,
    });
  }

  return responsiveImage;
};
